import { Component } from 'react'
import { connect } from 'react-redux'
import { has, isEmpty, compact, isArray } from 'lodash'
import I18n from 'i18next'
import PropTypes from 'prop-types'

import {
  Button,
  Box,
  Stack,
  InputAdornment,
  CircularProgress,
  TextField,
  MenuItem,
  FormControl,
  FormHelperText,
  FormControlLabel,
  Checkbox,
} from '@mui/material'

import { FiltersCont } from '@common/layout'
import { indicator as indicatorShape, location as locationShape } from '@common/utils/shapes'
import LocationSelect from '@common/components/location_select'
import { SubtypeSelect } from '@pages/im/components/im-type-specific-form/components/shared'

import {
  updateLocationDataAndRefresh,
  toggleIsPolygon,
  enablePolygonTools,
  updateManuallyEnteredPolygon,
  updateErrorInPolygon,
} from '@pages/im/actions/location'

import RadiusInput from '../radius-input/radius-input'
import PolygonInput from '../polygon-input/polygon-input'

const t = (name) => I18n.t(`indicator_message.location.inputs.${name}`)

class Inputs extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    editable: PropTypes.bool,
    enablePolygonTools: PropTypes.func.isRequired,
    errors: PropTypes.object,
    externalServiceErrors: PropTypes.arrayOf(PropTypes.string),
    features: PropTypes.arrayOf(PropTypes.object).isRequired,
    findLocationLoading: PropTypes.bool.isRequired,
    selectedIndicator: PropTypes.object,
    indicators: PropTypes.arrayOf(indicatorShape),
    indicatorSubtypes: PropTypes.arrayOf(PropTypes.string),
    updateIMFormValues: PropTypes.func.isRequired,
    indicatorsLoading: PropTypes.bool,
    isPolygon: PropTypes.bool.isRequired,
    polygonPoints: PropTypes.arrayOf(PropTypes.object),
    polygonPointsText: PropTypes.string,
    polygonToolsEnabled: PropTypes.bool,
    radius: PropTypes.number,
    selectedLocations: PropTypes.arrayOf(locationShape).isRequired,
    toggleIsPolygon: PropTypes.func.isRequired,
    updateLocationDataAndRefresh: PropTypes.func.isRequired,
    updateManuallyEnteredPolygon: PropTypes.func.isRequired,
    updateErrorInPolygon: PropTypes.func.isRequired,
  }

  selectLocations = (selectedLocations) => {
    const { updateLocationDataAndRefresh, isPolygon, toggleIsPolygon } = this.props
    if (isPolygon) {
      if (!selectedLocations) {
        toggleIsPolygon(false)
      }
      updateLocationDataAndRefresh(
        { selectedLocations: compact([selectedLocations]) },
        { refreshMap: { data: true, bounds: true } }
      )
    } else {
      selectedLocations = selectedLocations || []
      const removed = selectedLocations.length < this.props.selectedLocations.length
      const noEventsDisplayed = isEmpty(selectedLocations) && isEmpty(this.props.features)
      updateLocationDataAndRefresh(
        { selectedLocations },
        { refreshMap: { data: true, bounds: !removed || noEventsDisplayed } }
      )
    }
  }

  selectIndicator = (event) => {
    const { value } = event.target
    const selectedIndicator = this.props.indicators.find((indicator) => indicator.rmCode === value)
    this.props.updateIMFormValues({ indicatorSubtypes: [], selectedIndicator: selectedIndicator })

    this.props.updateLocationDataAndRefresh({}, { refreshMap: { data: true, bounds: false } })
  }

  radiusChanged = (event) => {
    let radius = parseFloat(event.target.value)
    if (isNaN(radius)) {
      radius = null
    }
    this.props.updateLocationDataAndRefresh(
      { radius, backupRadius: radius },
      { refreshMap: { data: true, bounds: false } }
    )
  }

  polygonPointsTextChanged = (event) => {
    if (event.target.value === '') return
    let polygonPoints = []
    try {
      const value = JSON.parse(event.target.value)
      if (isArray(value) && value.length >= 3 && value.every((el) => isArray(el) && el.length === 2)) {
        polygonPoints = value.map((latLng) => ({ latitude: latLng[1], longitude: latLng[0] }))
        this.props.updateManuallyEnteredPolygon(polygonPoints)
      } else {
        throw new Error()
      }
    } catch (ex) {
      this.props.updateErrorInPolygon()
    }
  }

  isPolygonChange = ({ target: { checked } }) => {
    this.props.toggleIsPolygon(checked)

    if (!checked) {
      this.props.enablePolygonTools(false)
    }
  }

  editPolygon = (event) => {
    event.preventDefault()

    this.props.updateLocationDataAndRefresh(
      { editable: true, draggable: true },
      { refreshMap: { data: true, bounds: false } }
    )
    this.props.enablePolygonTools(true)
  }

  radiusInputDisabled = () => this.props.disabled || this.props.isPolygon || this.props.selectedLocations.length === 0

  editButtonDisabled = () =>
    !this.props.isPolygon || !this.props.polygonPoints || (this.props.polygonToolsEnabled && this.props.editable)

  polygonInputDisabled = () => !this.props.isPolygon || this.props.polygonToolsEnabled

  hasError = (field) => {
    const { errors } = this.props
    return has(errors, field)
  }

  locationSelectValue = () => {
    const { selectedLocations, isPolygon } = this.props
    return isPolygon ? selectedLocations[0] : selectedLocations
  }

  handleSubtypeChange = (value) => {
    this.props.updateIMFormValues({ indicatorSubtypes: value })
  }

  render() {
    const {
      disabled,
      externalServiceErrors,
      findLocationLoading,
      selectedIndicator,
      isPolygon,
      polygonPointsText,
      polygonToolsEnabled,
      radius,
      selectedLocations,
      errors,
    } = this.props
    const { indicators, indicatorsLoading } = this.props
    const locationError = externalServiceErrors || this.props.errors.selectedLocations
    const processedLocationError = Array.isArray(locationError) ? locationError[0] : locationError

    return (
      <Stack direction={'row'} gap={2} justifyContent={'space-between'}>
        <FiltersCont sx={{ mb: 3, width: '100%' }}>
          <TextField
            select
            required
            value={selectedIndicator?.rmCode ?? ''}
            label={t('labels.indicator')}
            onChange={(ev) => this.selectIndicator(ev)}
            disabled={indicatorsLoading || disabled || polygonToolsEnabled}
            sx={{ minWidth: '18rem' }}
            data-testid="indicator_select"
            InputProps={
              indicatorsLoading
                ? {
                    endAdornment: (
                      <InputAdornment position="start" sx={{ pr: 2 }}>
                        <CircularProgress color="secondary" size={25} />
                      </InputAdornment>
                    ),
                  }
                : undefined
            }
            error={!!this.props.errors.indicator}
            helperText={this.props.errors.indicator}
          >
            {(indicators || []).map((option) => (
              <MenuItem key={option.rmCode} value={option.rmCode} data-testid={`indicator_${option.rmCode}`}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
          <SubtypeSelect
            subtypes={selectedIndicator?.subtypes}
            value={this.props.indicatorSubtypes ?? null}
            onChange={this.handleSubtypeChange}
            disabled={!selectedIndicator?.subtypes?.length}
            error={errors?.noIndicatorSubtypes}
            sx={{ width: '20rem' }}
          />
          <LocationSelect
            sx={{ minWidth: 450, maxWidth: 700 }}
            label={t('labels.locations')}
            isLoading={findLocationLoading}
            value={this.locationSelectValue()}
            onChange={this.selectLocations}
            isDisabled={disabled || findLocationLoading || polygonToolsEnabled}
            isMulti={!isPolygon}
            error={processedLocationError}
          />
          <RadiusInput
            value={radius}
            error={this.props.errors.radius}
            onChange={this.radiusChanged}
            disabled={this.radiusInputDisabled()}
          />
          <FormControl required error={!!this.props.errors.polygonPoints}>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={disabled || selectedLocations.length !== 1 || polygonToolsEnabled}
                  checked={isPolygon}
                  onChange={this.isPolygonChange}
                  data-testid="is_polygon"
                  sx={{ p: '7px' }}
                />
              }
              label={t('labels.is_polygon')}
            />
            {!!this.props.errors.polygonPoints && <FormHelperText>{this.props.errors.polygonPoints}</FormHelperText>}
          </FormControl>
          <PolygonInput
            value={polygonPointsText}
            error={this.props.errors.polygonPointsText}
            onChange={this.polygonPointsTextChanged}
            disabled={this.polygonInputDisabled()}
          />
          <Box flex={1} textAlign={'right'}>
            <Button onClick={(event) => this.editPolygon(event)} disabled={this.editButtonDisabled()}>
              Edit
            </Button>
          </Box>
        </FiltersCont>
      </Stack>
    )
  }
}

const mapStateToProps = (rootState) => {
  const {
    location: {
      errors,
      data: {
        radius,
        selectedLocations,
        isPolygon,
        polygonPoints,
        editable,
        findLocation: { loading: findLocationLoading },
      },
      externalServiceErrors,
      features,
      polygonToolsEnabled,
    },
  } = rootState.indicatorMessage
  return {
    errors,
    radius,
    selectedLocations,
    findLocationLoading,
    isPolygon,
    polygonPoints,
    externalServiceErrors,
    features,
    polygonToolsEnabled,
    editable,
  }
}

const mapDispatchToProps = {
  updateLocationDataAndRefresh,
  toggleIsPolygon,
  enablePolygonTools,
  updateManuallyEnteredPolygon,
  updateErrorInPolygon,
}

export default connect(mapStateToProps, mapDispatchToProps)(Inputs)
